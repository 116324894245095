<template>
  <v-container class="cookie-page container-wrapper">
    <v-row>
      <v-col class="cookie-page__header px-xl-0">
        <h1>Cookie Policy</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="cookie-page__content px-xl-0">
        <h4>Terms & Conditions</h4>
        <p>We are committed to providing a more user-friendly browsing experience and being transparent in the way we collect and process the user data when you access our website at
          <a href="https://www.ari.company/" target="_blank">www.ari.company</a> website (hereinafter “the website”). This Cookie Policy explains in detail what are cookies and how the received information can be used.</p>

        <h4>Who are we?</h4>
        <p>Individual entrepreneur Oleh Dankevych (“us”, “we”, “our”, or “ARI”) has created this website to communicate with casual visitors, potential clients, and companies interested in our services as well as to provide information about ARI and the IT outsourcing industry. We welcome visitors to casually view information on our website and to use the site to directly communicate with ARI</p>
        <p>By visiting the website, you receive the cookie's consent notification. You can agree to the use of cookies set out in this Policy or adjust personal browser settings. In case you reject the notification, the website may not work properly.</p>

        <h4>What are cookies?</h4>
        <p>Cookies are small pieces of text that are stored on your device when you visit a website. During your further visits to the website, the information stored in the cookie is sent back to the site. This allows the website to recognize you and tailor its content to your needs.</p>
        <p>ARI website uses different types of cookies:</p>
        <ul>
          <li>Session cookies are temporary. They automatically expire once the user leaves the website. Session cookies usually are of first-party provenance and enable the necessary functioning of the website.</li>
          <li>Persistent cookies remain on your device and expire at a specific date. These cookies can be set by the website itself or by third parties in the operation of the website.</li>
          <li>First-party cookies belong to our website and allow us to remember previous actions and data preferences of the user.</li>
          <li>Third-party cookies are set by third parties on the user’s device through our website, enabling third party features or functionality to be provided on or through the website (for example, report usage statistics or deliver advertisements).</li>
        </ul>
        <p>The first-party and third-party cookies have a wide range of purposes, the main ones are described below.</p>
        <p>Essential or strictly necessary cookies are a basic form of the website memory used to store preferences selected by users on a given site, allowing the use of some features, such as accessing secure areas of the website.</p>
        <p>Performance cookies collect information about the way visitors use the website, for example, which pages users visit most often, or whether they get error messages from the web pages. These cookies don’t collect information that identifies a visitor. This information is anonymous and helps improve the browsing experience.</p>
        <p>Functionality cookies allow the website to remember the choices you make (for example, the user name, language, or region) and provide enhanced, more personalized features. Functionality cookies are used to remember changes applied to the customizable parts of web pages. The received information from cookies can be anonymized. Besides, the browsing activity on other websites cannot be tracked</p>
        <p>Targeting or advertising cookies are used to deliver more interest-based advertising. They are also used to limit the number of times you see an advertisement and help measure the effectiveness of the advertising campaigns. This type of cookies is usually placed by advertising networks with our permission. They remember that you have visited a website and this information is shared with third parties. Quite often targeting or advertising cookies will be linked to the site functionality provided by the third parties.</p>

        <h4>Why does ARI use cookies?</h4>
        <p>Once you access the website, a number of cookies files are placed in your web browser. This information can be used to:</p>
        <ul>
          <li>Enable the website functionality and test new pages and features, aiming to see how users react to them.</li>
          <li>Process analytical data and track information on how the website is used, so that we can make improvements.</li>
          <li>Analyze the website in respect to user’s choices and actions in order to detect what changes the website behaviour or interface.</li>
          <li>Collect information about your activities on the website to provide you with the relevant advertising on our and other sites.</li>
        </ul>
        <p>The data collected through cookies, which may be placed on your device, will be stored only as long as it is needed to fulfill the purposes mentioned above.</p>

        <h4>What are your choices regarding cookies?</h4>
        <p>If you’d like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser</p>
        <p>Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.</p>
        <ul>
          <li>For the Chrome web browser, please visit this page from Google: <<a href="https://support.google.com/accounts/answer/32050" target="_blank">https://support.google.com/accounts/answer/32050</a>></li>
          <li>For the Internet Explorer web browser, please visit this page from Microsoft: <<a href="http://support.microsoft.com/kb/278835" target="_blank">http://support.microsoft.com/kb/278835</a>></li>
          <li>For the Firefox web browser, please visit this page from Mozilla: <<a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored" target="_blank">https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a>></li>
          <li>For the Safari web browser, please visit this page from Apple: <<a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a>></li>
          <li>For any other web browser, please visit your web browser official web pages.</li>
        </ul>

        <h4>Detailed information about cookies</h4>
        <p>You can learn more about cookies and third-party websites by following these links:</p>
        <ul>
          <li>All About Cookies: <a href="http://www.allaboutcookies.org/" target="_blank">http://www.allaboutcookies.org/</a></li>
          <li>Network Advertising Initiative: <a href="http://www.networkadvertising.org/">http://www.networkadvertising.org/</a></li>
        </ul>
        <p>If you want to exercise your rights, please <a href="/contact" target="_blank">contact us.</a></p>

        <h4>Contact details</h4>
        <p>If you have any questions or comments regarding this Cookie Policy, please send an email to ceo@ari.company with the subject line “Cookie Policy Request”</p>
        <p><i>Effective: November 01, 2021</i></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CookiePolicyPage"
}
</script>

<style lang="scss" scoped>
.cookie-page {
  flex: 1 0 auto;

  &__header {
    padding-top: 60px;
  }
  &__content {
    padding-bottom: 60px;
    word-wrap: break-word;
    h4 {
      margin: 16px 0;
    }
    p {
      font-size: 16px;
    }
    ul {
      margin-bottom: 16px;
      color: $dark-text;
    }
  }
}
</style>